<template>
  <div
    class="fixed bottom-0 left-0 right-0 w-screen bg-footer text-xs md:text-sm px-2 lg:hidden"
  >
    <div
      class="grid grid-flow-col justify-center content-end text-gray mb-3 grid-cols-5"
    >
      <NuxtLink
        activeClass="text-green-800 font-semibold"
        to="/transfer"
        class="mx-1 px-0 text-center self-end"
        ><p class="text-center items-center content-center flex flex-col">
          <span class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m7 20l-4-4l4-4l1.425 1.4l-1.6 1.6H11v2H6.825L8.4 18.6L7 20Zm7-3q-.425 0-.713-.288T13 16q0-.425.288-.713T14 15q.425 0 .713.288T15 16q0 .425-.288.713T14 17Zm4 0q-.425 0-.713-.288T17 16q0-.425.288-.713T18 15q.425 0 .713.288T19 16q0 .425-.288.713T18 17Zm-1-5l-1.425-1.4l1.6-1.6H13V7h4.175L15.6 5.4L17 4l4 4l-4 4ZM6 9q-.425 0-.713-.288T5 8q0-.425.288-.713T6 7q.425 0 .713.288T7 8q0 .425-.288.713T6 9Zm4 0q-.425 0-.713-.288T9 8q0-.425.288-.713T10 7q.425 0 .713.288T11 8q0 .425-.288.713T10 9Z"
              />
            </svg>
          </span>

          ฝาก/ถอน
        </p></NuxtLink
      >
      <NuxtLink
        activeClass="text-green-800 font-semibold"
        to="/betlist"
        class="mx-1 px-0 text-center self-end"
      >
        <p class="text-center items-center content-center flex flex-col">
          <span class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M10 14h4v-2h-4v2Zm0-3h8V9h-8v2Zm0-3h8V6h-8v2ZM8 18q-.825 0-1.413-.588T6 16V4q0-.825.588-1.413T8 2h12q.825 0 1.413.588T22 4v12q0 .825-.588 1.413T20 18H8Zm0-2h12V4H8v12Zm-4 6q-.825 0-1.413-.588T2 20V6h2v14h14v2H4ZM8 4v12V4Z"
              />
            </svg>
          </span>

          รายการโพย
        </p>
      </NuxtLink>
      <NuxtLink
        activeClass="text-green-800 font-semibold"
        to="/games"
        class="mx-1 px-0 text-center self-end active"
      >
        <p class="text-center items-center content-center flex flex-col">
          <span
            class="bg-white rounded-full p-2 shadow-md border-green-900 border-2"
            ><img
              class="w-9 text-center m-auto my-2"
              src="~assets/images/logo/dlto.svg"
              alt="deelotto"
          /></span>

          แทงหวย
        </p>
      </NuxtLink>

      <NuxtLink
        activeClass="text-green-800 font-semibold"
        to="/results"
        class="mx-1 px-0 text-center self-end"
      >
        <p class="text-center items-center content-center flex flex-col">
          <span class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M17 21q-.425 0-.713-.288T16 20v-7.95q0-.425.288-.713T17 11.05h4q.425 0 .713.288t.287.712V20q0 .425-.288.713T21 21h-4Zm0-2h4v-5.95h-4V19Zm-9 2v-2h2v-2H4q-.825 0-1.413-.588T2 15V5q0-.825.588-1.413T4 3h14q.825 0 1.413.588T20 5v4.05h-2V5H4v10h10v2h-2v2h2v2H8Zm.7-7.25L11 12l2.3 1.75l-.85-2.85l2.3-1.85H11.9l-.9-2.8l-.9 2.8H7.25l2.3 1.85l-.85 2.85ZM11 10Z"
              />
            </svg>
          </span>

          ผลรางวัล
        </p>
      </NuxtLink>
      <NuxtLink
        activeClass="text-green-800 font-semibold"
        to="/menu"
        class="mx-1 px-0 text-center self-end"
      >
        <p class="text-center items-center content-center flex flex-col">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zM7.35 18.5C8.66 17.56 10.26 17 12 17s3.34.56 4.65 1.5c-1.31.94-2.91 1.5-4.65 1.5s-3.34-.56-4.65-1.5zm10.79-1.38a9.947 9.947 0 0 0-12.28 0A7.957 7.957 0 0 1 4 12c0-4.42 3.58-8 8-8s8 3.58 8 8c0 1.95-.7 3.73-1.86 5.12z"
            />
            <path
              fill="currentColor"
              d="M12 6c-1.93 0-3.5 1.57-3.5 3.5S10.07 13 12 13s3.5-1.57 3.5-3.5S13.93 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"
            />
          </svg>

          เมนู
        </p>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup></script>

<style scoped>
/* a {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold;
}
a:hover {
  color: rgba(0, 0, 0, 1) !important;
} */
/* a.active {
  color: #000000 !important;
} */
.bg-footer {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(234, 179, 8, 1) 20%,
    rgba(234, 179, 8, 1) 100%
  );
  background: url("~/assets/images/bg-pattern/white.webp");
  background-repeat: repeat-x;
  background-size: 250px !important;
  background-position: 0px 17px;
}
</style>
